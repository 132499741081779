import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: JSON.parse(localStorage.getItem("LoginUser"))

  },
  mutations: {
    setUserInfo(state,value){
  state.userInfo=value
}
  },
  actions: {
  },
  modules: {
  }
})
