<template>
  <div class="app-container">
    <el-page-header
      @back="goBack"
      style="height: 40px; line-height: 40px; margin-top: 10px; color: #fff"
      title="返回"
    >
      <template #content>
        <span style="color: #ddd">片库</span>
      </template>
    </el-page-header>
    <el-input
      ref="keywordInput"
      style="color: #555"
      placeholder="剧名或主角名"
      v-model="keyword"
      size="large"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
      <template #append>
        <el-button type="info" @click="resetKeyword">搜索</el-button>
      </template>
    </el-input>
    <el-scrollbar>
      <div style="width: 100%; overflow-x: auto" class="videoMultiItem">
        <ul>
          <li v-for="item in datas" :key="item" @click="ShowVideo(item.id)">
            <img :src="item.picUrl" />
            <div style="clear: both">
              <el-text truncated tag="b"
                >{{ item.name }}({{ item.star }}星)</el-text
              >
              <p>
                <el-text truncated style="clear: both; font-size: 11px">{{
                  item.description
                }}</el-text>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </el-scrollbar>
  </div>
</template>

 <script  setup>
import { inject, reactive, ref, watch, onMounted } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import {
  post,
  isNull,
  RetrieveLocalData,
  SaveLocalData,
  ProcessRules,
  haveModule,
  getSysParm,
} from "@/api/common"; //常用方法
const props = defineProps({ //视频组件
  id: {
    searchType: String,
    default: "",
  }, 
});

const global = inject("global");
let _LoginUser = reactive({}); //当前用户
let scrHeight = ref(600); //总记录数
let datas = ref([]);
const keywordInput = ref(null);
let pageIndex = ref(0); //当前页面
let pageSize = ref(10); //显示记录数

const emit = defineEmits();
let keyword = ref("");
var type = "";
onMounted(() => {
  //页面初始化完成后，调用
  if (isNull(global.store.state.userInfo)) {
    //判断下是否有登录

    //global.router.push({ path: "/login" });

    return;
  }
  _LoginUser = global.store.state.userInfo;
  scrHeight.value = window.screen.height - 85;
  window.addEventListener("resize", handleResize);
  keywordInput.value.$el.focus();
  getData();
});
function setLoginUser(loginUser) {
  _LoginUser = loginUser;
  getData();
}

function handleResize() {
  var width = window.screen.width;

  scrHeight.value = window.screen.height - 85;
}
const ShowVideo = function (code) {
  global.router.push({ path: "/video/videoshow", query: { code: code } });
};
const resetKeyword = () => {
  pageIndex.value = 0;
  datas.value = [];
  getData();
};
const setSearchType = (param) => {
  type=param;
  pageIndex.value = 0;
  datas.value = [];
  getData();
};
// const loadData=()=>{
//   pageSize.value=0;
//   datas.value =[];
// getData();
// }
//查
const getData = () => {
  //获取当前页面数据

  const params = {
    keyword: keyword.value,
    pageSize: pageSize.value,
    pageIndex: pageIndex.value,
    orgCode: _LoginUser.orgCode,
    type:type
  };
  post("knowledge/GetVideoSeriesByKeyword", params, function (result) {
    if (isNull(result.datas)) {
      ElMessage("没有更多记录了！");
      //pageIndex.value =parseInt(pageIndex.value) -1
      return;
    }
    datas.value = datas.value.concat(result.datas);
    pageIndex.value = parseInt(pageIndex.value) + 1;
  });
};
const goBack = () => {
  emit("goBack");
};
/*
watch(
  () => props.searchType,
  (newVal) => {
    if (newVal,oldVal) {
      resetKeyword();
    }
  },
  { immediate: true, deep: true }
)*/

defineExpose({setSearchType})
</script>
<style>
.listItem li {
  clear: both;
  margin-top: 10px;
  height: 80px;
}
.listItem img {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  float: left;
}
.rightItem {
  float: left;
  width: calc(100% - 120px);
  margin-left: 10px;
}
.el-input__inner {
  color: #000;
}
</style>

