<template>
<div class="app-container" style="overflow-y:auto;" >


    <el-scrollbar :height="scrHeight+'px'" v-if="!showSearch">
        <div v-for="module in datas" :key="index">

<!--滚屏展播-->
<div v-if="module.showType == '9'">
  <el-carousel ref="carouselRef" arrow="always"  height="{{carouselHeight}}px" @touchstart="handleTouchStart"  @touchend="handleTouchEnd">
    <el-carousel-item v-for="child in module.children" :key="child" @click="ShowVideo(child.code)">
      <img
        style="width: 100%; height: {{carouselHeight}}px"
        :src="child.bGPic"
        fit="fill"
      />

    </el-carousel-item>
  </el-carousel>
</div>
<!--视频搜寻-->
<div v-if="module.showType == '51'">
<el-input
      v-model="input4"
      style="width: 100%"
      placeholder="剧名或主角名"
      @focus="goSearch"
      size="large"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
      <template #append>
        <el-button type="info">搜索</el-button>
      </template>
    </el-input>
    </div>
<!--4分组-->
<div v-if="module.showType == '4'">
   
  <el-row :gutter="10">
    <el-col
      :xs="6"
      :sm="6"
      :md="4"
      :lg="3"
      :xl="1"
      v-for="child in module.children"
      :key="child"
    >
    
      <div class="grid-content col-4-item"> <icon
        :class="'iconfont icon-'+child.icon"
        :style="'color:'+(child.color?child.color:'#3366CC')"
      ></icon>
      <p> {{ child.name }}</p>
       </div>
    </el-col>
  </el-row>
</div>

<!--2分组-->
<div v-if="module.showType == '22'">
  <el-row :gutter="10">
    <el-col
      :xs="12"
      :sm="6"
      :md="4"
      :lg="3"
      :xl="1"
      v-for="child in module.children"
      :key="child"
    >
     
      <div class="grid-content col-2-item">
        <icon
        :class="'iconfont icon-'+child.icon"
        :style="'color:'+(child.color?child.color:'#3366CC')"
      ></icon>
        {{ child.name }}</div>
    </el-col>
  </el-row>
</div>

<!--视频多显-->
<div v-if="module.showType == '55'" style="width:100%; overflow-x: auto;" class="videoMultiItem">
  <div style="background-color: #333; line-height:40px;margin-bottom: 10px;">
  <el-text class="mx-1" size="large" style="margin-left: 10px;color:#fff; ">{{module.name}}</el-text>
  <el-link   style="float: right;margin-right: 10px; color:#fff" @click="showMore(module.code)">更多>></el-link>
</div> 
  <div :style="'width:'+(module.children==null?360:120*module.children.length)+'px'">
    <ul>
        <li   v-for="child in module.children"
      :key="child" @click="ShowVideo(child.code)">
    <img       
        :src="child.bGPic"        
      />
      <div style="clear: both;"> <el-text truncated tag="b" style="color:#fff;">{{child.name}}</el-text>
        <p>
      <el-text truncated style="clear:both;font-size: 11px;color:#fff;">{{child.description}}</el-text>
    </p>
      </div>
 
    </li>
    </ul>
</div>
</div>

</div>
<div style="height:60px; text-align: center; border-top: 1px solid #555;line-height: 30px;">
 -- 我是有底线的--
  </div>
<!--底下Tab栏-->

<div  class="bottomTab">
    <el-row :gutter="10">
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
      @click="goHome()"
    >
    
      <div class="grid-content  tabSelected"> <icon
        class="iconfont icon-home"></icon>
      <p>首页</p>
       </div>
    </el-col>

    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
    @click="goSeries(1)"
    >
    
      <div class="grid-content 、 "> <icon
        class="iconfont icon-fenlei"></icon>
      <p>追剧</p>
       </div>
    </el-col>
    <el-col
    :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
      @click="goHotVideo()"
    >
    
      <div class="grid-content "> <icon
        class="iconfont icon-zhiliaoshifuzhen"></icon>
      <p>推荐</p>
       </div>
    </el-col>
    <el-col
    :xs="6"
      :sm="6"
      :md="6"
      :lg="6"
      :xl="6"
      @click="goUser()"
    >
    
      <div class="grid-content "> <icon
        class="iconfont icon-user"></icon>
      <p>个人中心</p>
       </div>
    </el-col>

  </el-row>
    </div>

</el-scrollbar>



<transition name="el-zoom-in-center">
  <seriesList ref="seriesListRef" @goBack="showSearch=false" v-show="showSearch" >
    </seriesList>
  </transition>
</div>


</template>
  
  
  
  
  <script setup>
import appconfig from "@/utils/config"; //常用方法
import { ElMessage ,TabsInstance ,ElCarousel} from "element-plus";
import { inject, reactive, ref, watch, onMounted } from "vue";
import { isNull, post, Request } from "@/api/common"; //常用方法
import seriesList from "./video/seriesList";
const carouselRef = ref(null)
// 获取全局对象`
// 获取全局对象`
const global = inject("global");
let carouselHeight = ref(20); //总记录数
let _LoginUser = reactive({}); //当前用户
let scrHeight = ref(600); //总记录数
let datas = ref([]);
let showSearch=ref(false);
let searchType=ref("");
const seriesListRef = ref(null)
onMounted(() => {
  window.addEventListener("resize", handleResize);
  //获取菜单
  scrHeight.value = window.screen.height -85;

  if (!isNull(global.store.state.userInfo)) {
    //判断下是否有登录
    _LoginUser = global.store.state.userInfo;
    if(_LoginUser.openId!= Request("openId")){
      Login();
    }else{
      RetrieveMpModule();
    }
  }else{
    Login();
  }

  //ElMessage(scrHeight.value.toString());
  
});
function handleResize() {
  var width = window.screen.width;
  if (width < 600) 
    carouselHeight.value = 200;
else
carouselHeight.value =200;
scrHeight.value = window.screen.height -85;
  
}
const Login = function () {
  var param = new Object();
  param.orgCode = appconfig.orgCode;
  param.openId = Request("openId");
  ElMessage("链接合法性校验中...");
  post(
    "sys/GetUserInfoByOpenId",
    param,
    function (result) {
        _LoginUser=result.data;
     // ElMessage("Authorize Sucess,Loading datas");
      // global.store.commit('setUserInfo', result.data); //保存用户信息
      localStorage.setItem("lang", result.data.language);
      // 设置本地存储数据 localStorage.setItem('key', 'value');
      localStorage.setItem("LoginUser", JSON.stringify(result.data));
      
      RetrieveMpModule();
    },
    function (result) {
      ElMessage("链接合法性校验失败:" + result.msg);
    }
  );
};

const RetrieveMpModule = function () {
  var param = new Object();
  param.orgCode = appconfig.orgCode;
  post("health/GetMyMpModule", param, function (result) {
    datas.value = result.datas;
  });
};
const ShowVideo=function(code){
   if(_LoginUser.id==null) 
   {
    ElMessage("加载中...");
    return;
   }
    global.router.push({ path: "/video/videoshow",query:{code:code} });
}
const goSearch=function(){
   if(_LoginUser.id==null) 
   {
    ElMessage("加载中...");
    return;
   }
   showSearch.value=true;
   searchType.value="";
  seriesListRef.value.setSearchType("");
}
const showMore=function(code){
  searchType.value=code;
  showSearch.value=true;
 
 seriesListRef.value.setSearchType(code);
}


const goHome = () => {
  if(_LoginUser.id==null) 
   {
    ElMessage("加载中...");
    return;
   }
  global.router.push({ path: "/", query: { openId: _LoginUser.openId } });
};
const goSeries=function(showType){
  if(_LoginUser.id==null) 
   {
    ElMessage("加载中...");
    return;
   }
//type 1,追剧，2，观看历史，3，推荐
global.router.push({ path: "/video/videoseries",query:{showType:showType} });
}
const goHotVideo=function(){
  if(_LoginUser.id==null) 
   {
    ElMessage("加载中...");
    return;
   }
//type 1,追剧，2，观看历史，3，推荐
global.router.push({ path: "/video/hotvideo"});
}
const goUser=function(){
  if(_LoginUser.id==null) 
   {
    ElMessage("加载中...");
    return;
   }
//
global.router.push({ path: "/video/sysuser"});
}

let startX;
const handleTouchStart=(event)=> {
  startX = event.touches[0].clientX;
    };

  
    const  handleTouchEnd=(event)=> {
      const currentX = event.changedTouches[0].clientX;
      const delta = currentX - startX;
 
      if (delta > 100) { // 向左滑动
        // 执行上滑操作
        //swapeDown();
      //  carouselRef.value.prev();
      } else if(delta <-100) {//向右
        // 向上滑动
        //swapeUp();
        //console.log('向上滑动');
       // carouselRef.value.next();
      }
    };
</script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

/* pc */
@media only screen and (min-width: 600px)  {
	  
    .col-2-item{
    
}
}

  /*phone*/
@media only screen and (min-width: 0px) and (max-width: 420px) {
	.col-2-item{
        font-size: 16px;
        line-height:50px;
        text-align: center;
}
.col-2-item icon{
 font-size: 32px;

}
.col-4-item{
        font-size: 16px;
        text-align: center;
}
.col-4-item icon{
 font-size: 32px;
}


}


</style>
  