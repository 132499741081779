import  appconfig  from "./config"; //常用方法
import axios from 'axios'
import {  ElMessageBox } from 'element-plus'

import router from '../router'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: appconfig.serviceUrl,//process.env.BASE_API, // api的base_url
  timeout: 15000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Token'] = getToken()
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非0是抛错 可结合自己业务进行修改
  */
   
    const res = response.data
    if (res.Code == 100001) {
      router.push({ path: '/login' })
    }
    if (res.code != 0) {
      
        
        ElMessageBox.alert(res.msg, '出错提示', {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: "确认",
         
        })
    }
    

    return res
  },
  error => {
   
    ElMessageBox.alert('err' + error, '出错提示', {
      // if you want to disable its autofocus
      // autofocus: false,
      confirmButtonText: "确认",
     
    })
    return Promise.reject(error)
  }
)

export default service
