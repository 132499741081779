import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import i18n from "./locale/i18n";
import router from './router' //路由
import store from './store' //存储
import zhCn from "element-plus/es/locale/lang/zh-cn"
import 'element-plus/dist/index.css'

import * as ElIcon from '@element-plus/icons-vue'
// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

//修改分页描述

zhCn.el.pagination.total = "总共：" + `{total}` + "条"
zhCn.el.pagination.goto = "跳转至"

const app = createApp(App)
app.use(ElementPlus, { locale: zhCn })
app.use(i18n); //多语言
app.use(router)
// 配置全局变量 页面中使用 inject 接收
app.provide('global',{
    store,
    router,
   
  }) 

 
  Object.keys(ElIcon).forEach((key) => {
    app.component(key, ElIcon[key])
  })


app.mount('#app')
