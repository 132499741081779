/* eslint-disable no-undef */
import request from '@/utils/request'
import indDB from '@/utils/indexdb'
import { ElMessageBox,ElMessage } from 'element-plus'
// 提交服务
// method:方法地址， 如:sys/checkUser
// params:传入参数json格式
export const post = (method, params, callback, errCallback) => {

  request.post(method, params).then((result) => {
    if (result.code == 0) {
      callback(result)
    } else {
      ElMessageBox.alert("方法:" + method + "出错，具体：" + result.Message);
      // if you want to disable its autofocus)
      // alert("方法:" + method + "出错，具体：" + result.Message);
      if (errCallback != null) {
        errCallback(result);
      }
    }

  }).catch((err) => {
    console.log(err);
    if (errCallback != null) //没加这句判断会出现errCallback is no defunction错误
      errCallback({ "code": 10001, "msg": err });

  });

}
//保存本地数据
export const SaveLocalData = (key, obj) => {
  if (obj == null) {
    window.localStorage.removeItem(key)
  } else {
    window.localStorage.setItem(key, JSON.stringify(obj))
  }
}
//获取本地存储数
export const RetrieveLocalData = function (key) {
  if ((window.localStorage.getItem(key) == null) || (window.localStorage.getItem(key).length == 0)) return null;
  return JSON.parse(window.localStorage.getItem(key))
}

export const SaveDBData = function (key, userInfo, obj) {
//  var indDB = new indexDB();

  if (userInfo != null)
    indDB.SaveData(userInfo.userCode + "_" + userInfo.orgCode + "_" + key, obj);
  else
    indDB.SaveData(key, obj);


}
export const RetrieveDBData = function (key, userInfo, callback) {
  

  if (userInfo != null)
    return indDB.GetData(userInfo.userCode + "_" + userInfo.orgCode + "_" + key, callback);
  else
    return indDB.GetData(key, callback);
  //if ((window.localStorage.getItem(_LoginUser.userCode + "_" + _LoginUser.orgCode + "_" + key) == null) || (window.localStorage.getItem(_LoginUser.userCode + "_" + _LoginUser.orgCode + "_" + key).length == 0)) return null;
  //return JSON.parse(window.localStorage.getItem(_LoginUser.userCode + "_" + key));

}
///保存操作日记

export const addSystemLog = function (moduleName, operation,keyword) {

  var data = {}
  data.moduleName = moduleName
  data.operation = operation
  data.keyword=keyword
  return request.post(
    '/Sys/SaveSystemLog',
    data
  )
}
//生成Guid
export const newGuid = function () {
  return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
//格式化日期
//把日期格式化为yyyy-MM-dd HH:mm:SS的值
//date为日期实体
//str为返回的数据样式 如yyyy-MM-dd
export const formatDate = function (date, str) {
  if (date == null) return "";

  if ((typeof date) == 'string') {
    if (date.indexOf("Date") > 0) //处理json日期
      date = new Date(parseInt(date.replace("/Date(", "").replace(")/", "").split("+")[0]));
    else
      date = new Date(date);
  }
  // try {
  str = Replace(str, "yyyy", date.getFullYear());
  str = Replace(str, "MM", GetFullValue(date.getMonth() + 1));
  str = Replace(str, "dd", GetFullValue(date.getDate()));
  str = Replace(str, "HH", GetFullValue(date.getHours()));
  str = Replace(str, "mm", GetFullValue(date.getMinutes()));
  str = Replace(str, "SS", GetFullValue(date.getSeconds()));

  return str;
}
//判断是否为空
export function isNull(obj) {
  if (obj == null) return true;
  if (obj.length == 0) return true;
  return false;
}
//判断用户是否有某个权限
//userInfo 用户信息
//id 权限Id
export const haveModule = function (userInfo, id) {
  if (userInfo == null) return false
  if (userInfo.moduleIds == null) return false
  for (var i = 0; i < userInfo.moduleIds.length; i++) {
    if (userInfo.moduleIds[i] === id) {
      return true
    }
  }
  return false
}
//获取系统参数
export const getSysParm = function (userInfo, code) {
  if (userInfo == null) return null
  
  if (userInfo.organize == null) return null;
  if (userInfo.organize.sysParams == null) return null;
  for (var i = 0; i < userInfo.organize.sysParams.length; i++) {
      if (userInfo.organize.sysParams[i].code.toLowerCase() == code.toLowerCase()) {
          return userInfo.organize.sysParams[i].value;
      }
  }
  return null;
}
//清除缓存
export const ClearBuffer = function (userInfo) {
 
  indDB.SaveData(userInfo + '_AllBaseDict', null);
}
export const deepCopy = (object) => {
  let result;
  if (Object.prototype.toString.call(object) == "[object Object]") {
    result = {};
  } else if (Object.prototype.toString.call(object) == "[object Array]") {
    result = [];
  } else {
    return "不符合深拷贝的数据类型";
  }
  // 遍历空对象或者是空数组  也就是要拷贝的对象
  for (let key in object) {
    if (typeof object[key] == "object") {
      result[key] = deepCopy(object[key]);
    } else {
      result[key] = object[key];
    }
  }
  return result;
};

let pageIndex = 0;
var allBaseDict = [];
// 获取字典内容获取字典值
export const GetDicts = function (dictCode, userInfo, callback) {
  this.RetrieveDBData('AllBaseDict', userInfo, function (datas) {
    var allBaseDict = datas
    if (allBaseDict != null) {
      var baseDicts = Enumerable.From(allBaseDict).Where(function (x) { return x.dictCode == dictName }).ToArray();
      callback(baseDicts)
      return
    }
    allBaseDict = [];
    pageIndex = 0;
    RetrieveBaseDictData(dictCode, userInfo, callback)
  })
}
const RetrieveBaseDictData = function (dictCode, userInfo, callback) {
  var filter = [];
  var condition = new Object();

  condition.FieldName = 'orgCode';
  condition.Opt = '=';
  condition.Value = userInfo.orgCode;
  condition.FieldType = "string";
  filter.push(condition);
  condition = new Object();
  condition.FieldName = 'validFlag';
  condition.Opt = '=';
  condition.Value = "1";
  condition.FieldType = "string";
  filter.push(condition);

  var param = new Object();
  param.filter = filter;
  param.pageSize = 100;
  param.pageIndex = pageIndex;
  param.orderField = "dictCode,sortNo";
  ElMessage('正在加载字典...')
  post("base/GetBaseDict", param, function (result) {
    if (!isNull(result.datas)) {
      allBaseDict.push(result.datas);
      pageIndex++;
      RetrieveBaseDictData(dictCode, userInfo, callback);
    }
    else {
      SaveLocalData("AllBaseDict", allBaseDict);
      var baseDicts = Enumerable.From(allBaseDict).Where(function (x) { return x.dictCode == dictName }).ToArray();
      callback(baseDicts)
      ElMessage('加载字典完毕...')
    }
  });
}
export const ReplaceAll = function (str, from, to) {
  return str.split(from).join(to);
}
export const ProcessRules = function (validateInfos) {
  validateInfos.forEach(element => {
    if(element.isRequire){//必输
 eval("rules."+elemnet.fieldName+".push({required:true,message:'"+elemnt.cName+"必须输入',trigger:'blur'})")
}
if(isNull(element.dataLength) && element.dataLength > 0){//字段长度
 eval("rules."+elemnet.fieldName+".push({min:1,max:"+element.dataLength+",message:'"+elemnt.cName+"最大字数不能大于"+element.dataLength+"',trigger:'blur'})")
}
//其他校验函数，通过,分隔开
if (!isNull(element.validations )) {//判断其他校验函数
     var functions = element.validations
     functions.forEach(func => {
      eval("rules."+elemnet.fieldName+".push({validator:"+func.validate+",message:'"+func.dataLength+"',trigger:'blur'})")
     });     
 }
});
}
export const  Request=function(key) {
  key = key.toLowerCase();
  var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
  var result = window.location.search.toLowerCase().substr(1).match(reg);
  return result ? decodeURIComponent(result[2]) : null;
}

// 替换字符串 
function Replace(str, from, to) {
  return str.split(from).join(to);
}
function GetFullValue(v) {

  if (v > 9) return v.toString();
  return "0" + v;
}
